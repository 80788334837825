.section {
  max-width: 1500px;
  padding: 3rem 10vw;
  background-color: var(--background-color);
  color: var(--text-color);
}

.section h1 {
  margin-bottom: 1rem;
  font-size: clamp(16px, 5vw, 32px);
  text-transform: uppercase;
  letter-spacing: 0.125rem;
}

.section p {
  margin-bottom: 1rem;
  font-size: clamp(12px, 4vw, 16px);
  line-height: 1.5rem;
  letter-spacing: 0.075rem;
}
