.top_image {
  height: 112vh;
  background-image: url(../assets/images/low-resolution/220609_151823.jpg);
  background-position-x: 60%;
  background-position-y: 70%;
}

.bottom_image {
  height: 550px;
  background-image: url(../assets/images/low-resolution/220609_151710.jpg);
  background-position-y: 55%;
}

@media screen and (min-width: 1920px) {
  .bottom_image {
    background-position-y: 55%;
  }
}

@media screen and (max-width: 768px) {
  .top_image {
    background-image: url(../assets/images/low-resolution/220609_151823_vertical.jpg);
    background-position-x: 65%;
  }

  .bottom_image {
    background-image: url(../assets/images/low-resolution/220609_151712.jpg);
    background-position-x: 30%;
  }
}

/* Animated arrow */
.arrow_container {
  position: fixed;
  bottom: 10vh;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.25) 40%,
    rgba(0, 0, 0, 0) 70%
  );
  transition: opacity 0.5s ease-in-out;
}

.arrow_container.visible {
  opacity: 1;
}

.arrow_container:not(.visible) {
  opacity: 0;
}

.arrow_down {
  height: 50px;
  transition: color 0.5s ease-in-out;
  animation: scroll 0.35s ease-in alternate infinite;
}

.arrow_down.visible {
  color: white;
}

.arrow_down:not(.visible) {
  color: transparent;
}

@keyframes scroll {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}
