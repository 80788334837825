.image {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-size: cover;
}

.top_image {
  height: 70vh;
  background-image: url(../assets/images/low-resolution/220609_145633.jpg);
  background-position-y: 20%;
}

.bottom_image {
  height: 450px;
  background-image: url(../assets/images/low-resolution/220426_151243.jpg);
  background-position-y: 60%;
}

@media screen and (max-width: 1023px) {
  .top_image {
    background-image: url(../assets/images/low-resolution/220609_150057.jpg);
    background-position-y: 80%;
  }
}

@media screen and (max-width: 480px) {
  .bottom_image {
    background-image: url(../assets/images/low-resolution/220609_153729.jpg);
  }
}
