:root {
  --primary-color: #e4a533;
  --secondary-color: #562a12;
  --text-color: #582d1a;
  --warning-color: #c21010;
  --background-color: #fffdf5;
  --hover-color: #fffdf5;
  --background-hover-color: #caab96;
  --background-active-color: #b59987;
  --header-transition-duration: 0.4s;
}

#root {
  height: 100vh;
  background-color: var(--background-color);
  scroll-behavior: smooth;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1024px) {
  #root {
    display: flex;
    flex-direction: column;
  }
}
