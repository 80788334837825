.top_image {
  height: 70vh;
  grid-area: image;
  background-image: url(../assets/images/low-resolution/220609_152810.jpg);
  background-size: cover;
  background-position-y: 45%;
}

@media screen and (max-width: 768px) {
  .top_image {
    grid-area: unset;
    width: 100vw;
    height: 250px;
  }
}
