.nav {
  color: var(--secondary-color);
}

.header:not(.collapse) .nav.contrast {
  color: var(--background-color);
}

.header {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 30px 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  transition: padding var(--header-transition-duration) ease-in-out,
    background-color var(--header-transition-duration) ease-in-out,
    color var(--header-transition-duration) ease-in-out;
}

.header:not(.collapse) {
  background-color: transparent;
  box-shadow: none;
}

.header.collapse {
  padding: 0 10vw;
}

.items {
  list-style: none;
  display: flex;
  margin: 0;
}

.item {
  position: relative;
  overflow: hidden;
  padding: 20px;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  background-color: transparent;
  transition: background-color 0.2s;
  transition: box-shadow 0.2s;
}

.header:not(.collapse) .item:hover {
  box-shadow: inset 0px 0px 27px 0px rgba(0, 0, 0, 0.1);
}

.header.collapse .item:hover {
  background-color: var(--background-hover-color);
  color: var(--hover-color);
}

.link,
.link:hover {
  text-decoration: none;
  color: inherit;
}

.link.selected li {
  text-decoration: underline;
}

.logo {
  display: flex;
}

.logo img {
  height: 60px;
  transition: height var(--header-transition-duration) ease-in-out;
}

.header.collapse .logo img {
  height: 40px;
}

.hamburger {
  --bar-color: var(--text-color);
  --bar-width: 30px;
  --bar-height: 4px;
  --transition-duration: 0.2s;

  position: relative;
  width: var(--bar-width);
  height: var(--bar-width);
  cursor: pointer;
  overflow: hidden;
}

.hamburger_wrapper {
  position: relative;
}

.hamburger_wrapper::before {
  content: '';
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 253, 245, 0.5);
  border-radius: 50%;
  transition: transform var(--header-transition-duration) ease-in-out;
}

.header.collapse .hamburger_wrapper::before {
  transform: scale(0);
}

.header:not(.collapse) .hamburger_wrapper::before {
  transform: scale(2);
}

.hamburger .line {
  position: absolute;
  height: var(--bar-height);
  width: var(--bar-width);
  background-color: var(--bar-color);
  border-radius: 2px;
  transition: transform ease-in-out, width ease-in-out;
  transition-duration: var(--transition-duration);
  box-shadow: rgba(255, 253, 245, 0.2) 0px 7px 29px 0px;
}

.line:nth-child(1) {
  top: 0;
}

.line:nth-child(2),
.line:nth-child(3),
.line:nth-child(4) {
  top: 0;
  bottom: 0;
  margin: auto;
}

.line:nth-child(5) {
  bottom: 0;
}

.hamburger.active .line:nth-child(even) {
  transition-delay: var(--transition-duration);
}

.hamburger:not(.active) .line:nth-child(odd) {
  transition-delay: var(--transition-duration);
}

.hamburger.active .line:nth-child(odd) {
  width: 0;
}

.hamburger.active .line:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger.active .line:nth-child(4) {
  transform: rotate(-45deg);
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 30px 5vw;
  }

  .items {
    padding: 0;
  }

  .item {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .header.collapse {
    padding: 2vh 10vw;
  }

  .nav {
    z-index: 1;
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    transition: transform var(--header-transition-duration) ease-in-out;
    transform: translateY(-100vh);
  }

  .nav.active {
    transform: translateY(0);
  }

  .items {
    flex-direction: column;
  }

  .item {
    font-size: 2.5rem;
  }

  .logo img {
    height: 40px;
  }
}
