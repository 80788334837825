.top_image {
  height: 70vh;
  background-image: url(../assets/images/low-resolution/220609_151633.jpg);
  background-position-y: 60%;
}

.middle_image {
  height: 450px;
  background-image: url(../assets/images/low-resolution/220426_162741.jpg);
  background-position-y: 50%;
}

.bottom_image {
  height: 450px;
  background-image: url(../assets/images/low-resolution/220609_151913.jpg);
  background-position-y: 40%;
  background-position-x: center;
}

@media screen and (min-width: 1920px) {
  .middle_image {
    height: 550px;
  }

  .image_coiuivvy {
    background-position-y: 55%;
  }
}

@media screen and (max-width: 1024px) {
  .top_image {
    background-position-x: 85%;
    background-position-y: top;
  }
}

@media screen and (max-width: 768px) {
  .middle_image {
    background-image: url(../assets/images/low-resolution/220609_150520.jpg);
    background-position-y: 65%;
  }
}
