.page {
  align-items: stretch;
}

.section {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 3rem;
  padding: 0 3rem;
}

.section:not(:first-of-type) {
  padding-top: 0;
}

.section:not(:last-of-type) {
  padding-bottom: 0;
}

.info {
  margin: 5rem 0 2rem;
  font-style: normal;
  color: var(--text-color);
}

.info p {
  margin: 0;
}

.info a {
  text-decoration: none;
  color: inherit;
}

.basics svg {
  height: 24px;
  width: 24px;
}

.name {
  font-weight: 2rem;
  font-weight: bold;
}

.social_medias {
  color: var(--text-color);
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
}

.form_error {
  margin: 0;
  font-size: 0.75rem;
  color: var(--warning-color);
}

.input {
  outline: 0;
  padding: 0.5rem 1rem;
  border: 1px solid lightgrey;
  border-radius: 4px;
  resize: none;
  color: inherit;
  font-family: inherit;
}

textarea.input {
  min-height: 150px;
}

.button {
  align-self: flex-end;
  padding: 0.75rem 1rem;
  cursor: pointer;
  background-color: var(--background-color);
  border: 1px solid var(--text-color);
  border-radius: 100px;
  font-family: inherit;
  letter-spacing: 0.075rem;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.167s cubic-bezier(0.4, 0, 0.2, 1);
}

.button:hover:not(:disabled),
.button:focus:not(:disabled) {
  background-color: var(--background-hover-color);
  border-color: var(--background-hover-color);
  color: #ffffff;
}

.button:active:not(:disabled) {
  background-color: var(--background-active-color);
  border-color: var(--background-active-color);
}

.button:disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.08);
  border-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.success_message,
.error_message {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  text-align: center;
  transition: transform ease-in-out 0.5s 0.5s;
}

.success_message.active,
.error_message.active {
  transform: translateX(0);
}

.success_message p,
.error_message p {
  margin: 0 2rem;
}

@media screen and (max-width: 1024px) {
  .section {
    padding: 0 6vw;
  }
}

@media screen and (max-width: 768px) {
  .form_wrapper {
    display: unset;
    gap: unset;
  }
}
