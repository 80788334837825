.page {
  background: var(--background-color);
}

.for_who {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.for_who .images {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.for_who .images img {
  height: 32px;
  width: 32px;
}

.warning {
  margin: 2rem auto 5rem;
  padding: 0 2rem;
  font-size: 1.5rem;
  text-align: center;
  text-decoration: underline;
}

/*
* Grid Layout
*/

.grid {
  max-width: 2100px;
  margin-bottom: 3rem;
  padding: 0 3rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
}

.grid > * {
  flex-basis: 750px;
}

@media screen and (max-width: 1600px) {
  .grid > * {
    flex-basis: 650px;
  }
}

@media screen and (max-width: 1450px) {
  .grid > * {
    flex-basis: 600px;
  }
}

@media screen and (max-width: 1300px) {
  .grid > * {
    flex-basis: 550px;
  }
}

@media screen and (max-width: 1200px) {
  .grid > * {
    flex-basis: 750px;
  }
}

@media screen and (max-width: 1024px) {
  .bottom_image {
    height: 350px;
    background-position-x: 60%;
  }
}

@media screen and (max-width: 768px) {
  .grid {
    padding: 0 1.5rem;
  }
}
