.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.container.column {
  flex-direction: column;
}

.container.column.stretch {
  align-items: stretch;
}
