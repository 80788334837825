.footer {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.social_medias {
  margin: auto;
}

.social_medias a {
  color: unset;
}

.footer_nav {
  margin: 0 auto 1rem auto;
}

.footer_nav .items {
  display: flex;
  gap: 12px;
  padding: 0;
  list-style: none;
}

.footer_nav .items .link {
  text-decoration: none;
  color: unset;
  font-size: 0.875rem;
}

.footer_nav .items .link:hover {
  text-decoration: underline;
}
