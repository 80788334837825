.image {
  width: 100%;
  margin-bottom: 2rem;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  background-size: cover;
}

.image.top {
  align-items: flex-start;
}

.image.center {
  align-items: center;
}

.image.bottom {
  align-items: flex-end;
}

.image h1 {
  margin: 2rem 4rem;
  text-align: center;
  color: white;
  font-size: clamp(16px, 2vw, 32px);
  text-transform: uppercase;
  letter-spacing: 0.125rem;
}

.title_wrapper {
  border-radius: 1em;
  background-color: rgb(88, 45, 26, .4);
}

@media screen and (max-width: 768px) {
  .title_wrapper {
    max-width: 90%;
  }

  .image h1 {
    margin: 2rem;
  }
}
