.card {
  padding: 2rem;
  color: var(--text-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card h2 {
  padding: 0.25rem 2rem;
  font-size: 1.5rem;
  color: var(--background-color);
  background-color: var(--background-hover-color);
}

.card h2,
.card p {
  text-align: center;
}

.card .price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}

.card details summary {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  list-style: none;
  font-size: 1.5rem;
}

.card details summary::-webkit-details-marker {
  display: none;
}

.card details summary button {
  border: none;
  background: none;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.card details summary button:hover,
.card details summary button:focus {
  font-weight: bold;
}

.card .content {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card .content ul {
  margin-left: 3rem;
}

@media screen and (max-width: 1024px) {
  .card {
    padding: 1.5rem;
  }

  .card .content {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .card h2,
  .card .price,
  .card details summary {
    font-size: 1.25rem;
  }
}
