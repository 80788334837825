.main {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
}

.main.landscape {
  background-image: url(../assets/images/low-resolution/220609_151844.jpg);
}

.main:not(.landscape) {
  background-image: url(../assets/images/low-resolution/220609_153618.jpg);
}

.main h1 {
  position: absolute;
  top: 20%;
  width: 100vw;
  padding: 24px 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  font-size: clamp(14px, 5vw, 38px);
}

.main.tablet h1 {
  top: 40%;
}
